<template>
    <section
        :class="[
            'popular_productions-container',
            'dashboard-container',
            { autoheight: showCard },
            { 'unusual-height': isNotResponseAPIData || isNotHasProductions }
        ]"
    >
        <div class="dashboard-title production-title">
            {{ `${getProductionsMonth}月` }}十大暢銷商品
        </div>
        <template
            v-if="!isNotHasProductions && !isNotResponseAPIData && !isError"
        >
            <div :class="['production-list-box', { preload: isLoading }]">
                <TagMenu
                    :isDashboard="true"
                    :options="getProductionTab"
                    v-model="currentMenuIndex"
                />
                <div>
                    <ul :class="['column']">
                        <li v-for="(option, index) in column" :key="index">
                            {{ option }}
                        </li>
                    </ul>
                    <div
                        :class="[
                            'list',
                            { 'list_show-all': showCard },
                            { 'list-showScroll': !isLoading }
                        ]"
                    >
                        <!-- todo 設計共用元件 -->
                        <div
                            :class="[
                                'card',
                                ,
                                { 'card-bg': !isLoading },
                                { 'text-line': isLoading }
                            ]"
                            v-for="(data, index) in getProductions"
                            :key="index"
                        >
                            <div class="card-rank" v-if="!isLoading">
                                <figure
                                    v-if="data.rank === 1"
                                    class="card-rank-icon"
                                >
                                    <img
                                        src="@/assets/images/icon_crown@3x.webp"
                                        alt=""
                                    />
                                </figure>
                                <figcaption>
                                    {{ this.isLoading ? '' : data.rank }}
                                </figcaption>
                            </div>
                            <div class="card-company">
                                <div class="card_mobile">保險公司</div>
                                {{ this.isLoading ? '' : data?.supplier }}
                            </div>
                            <div class="card-product">
                                <div class="card_mobile">商品名稱</div>
                                <div class="card-product-name">
                                    {{
                                        this.isLoading ? '' : data?.productName
                                    }}
                                </div>
                            </div>
                            <div class="card-remark" v-if="!isLoading">
                                <div class="card_mobile">
                                    <figure
                                        v-if="data.rank === 1"
                                        class="card-rank-icon"
                                    >
                                        <img
                                            src="@/assets/images/icon_crown@3x.webp"
                                            alt=""
                                        />
                                    </figure>
                                    <figcaption>
                                        {{ this.isLoading ? '' : data.rank }}
                                    </figcaption>
                                </div>
                                <figure class="card-remark">
                                    <img :src="data.image" alt="" />
                                </figure>
                            </div>
                            <div class="card-price">
                                <div class="card_mobile">FYC</div>
                                {{ this.isLoading ? '' : data?.totalFyb }}
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    :class="['arrow']"
                    @click="
                        ;(showCard = !showCard),
                            this.$setGaEvent(`TopTenProdExpand`, 'click-icon')
                    "
                >
                    <figure>
                        <img
                            :class="[{ rotate: showCard }]"
                            src="@/assets/images/btn_sort_arrow.webp"
                            alt="展開資料按鈕"
                        />
                    </figure>
                </div>
            </div>
            <div class="production_diagram">
                <div
                    class="production_diagram_item"
                    v-for="state in productionState"
                    :key="state.title"
                >
                    <figure>
                        <img :src="state.imageUrl" :alt="state.title" />
                    </figure>
                    <figcaption>{{ state.title }}</figcaption>
                </div>
            </div>
        </template>
        <DashboardErrorMessage
            v-else-if="!isLoading && isNotResponseAPIData && isError"
            functionName="getDashboardTopTenProductionsAPI"
            @reacquire="storeProductionsAPI"
        />
        <DashboardNoDataVue
            v-else-if="!isLoading && isNotHasProductions && !isError"
            message="本月沒有十大暢銷商品"
        />
    </section>
</template>
<script>
import DashboardErrorMessage from '@/containers/home/DashboardErrorMessage.vue'
import DashboardNoDataVue from './DashboardNoData.vue'
import TagMenu from '../../components/TagMenu.vue'

import { getDashboardTopTenProductionsAPI } from '../../assets/javascripts/api'
export default {
    name: 'DashboardPopularProductions',
    components: { TagMenu, DashboardErrorMessage, DashboardNoDataVue },
    methods: {
        storeProductionsAPI: async function () {
            this.isError = false
            this.isLoading = true
            try {
                let response = await getDashboardTopTenProductionsAPI()
                let apiList = response.data
                this.$store.commit('set', {
                    dashboardPopularProductions: apiList
                })
            } catch (error) {
                this.isError = true
                this.$store.commit('set', { dashboardPopularProductions: {} })
            } finally {
                this.isLoading = false
            }
        }
    },
    watch: {
        currentMenuIndex: function (newVal) {
            this.$setGaEvent(`popularProductions${newVal}`, 'toggle_menu')
        }
    },

    data() {
        return {
            productionState: [
                {
                    imageUrl: require('../../assets/images/icon_rise@3x.webp'),
                    title: '較前一個月排名上升'
                },
                {
                    imageUrl: require('../../assets/images/icon_decline@3x.webp'),
                    title: '較前一個月排名下降'
                },
                {
                    imageUrl: require('../../assets/images/icon_flat@3x.webp'),
                    title: '與前一個月排名持平'
                },
                {
                    imageUrl: require('../../assets/images/icon_newcomer@3x.webp'),
                    title: '本月新進榜'
                }
            ],
            column: ['排名', '保險公司', '商品名稱', '', 'FYC'],
            isLoading: false,
            isError: false,
            currentMenuIndex: `singlePaymentRank`,
            showCard: false
        }
    },
    computed: {
        getProductions: function () {
            let useApi = this.$store.state.dashboardPopularProductions
            if (Object.keys(useApi).length > 0) {
                return useApi[this.currentMenuIndex]?.map((data) => {
                    switch (data.remark) {
                        case 'balance': {
                            data.image = require('../../assets/images/icon_flat@3x.webp')
                            break
                        }
                        case 'up': {
                            data.image = require('../../assets/images/icon_rise@3x.webp')
                            break
                        }
                        case 'down': {
                            data.image = require('../../assets/images/icon_decline@3x.webp')
                            break
                        }
                        case 'new': {
                            data.image = require('../../assets/images/icon_newcomer@3x.webp')
                            break
                        }
                    }
                    data.totalFyb = this.$numberWithComma(data.totalFyb)
                    return data
                })
            } else {
                return null
            }
        },
        getProductionsMonth: function () {
            let getAPI = this.$store.state.dashboardPopularProductions
            if (getAPI[this.currentMenuIndex]?.length > 0) {
                return this.$store.state.dashboardPopularProductions.month
            } else {
                return this.setFakeMonth
            }
        },
        setFakeMonth: function () {
            let today = new Date()
            let isJAN = today.getMonth() === 0
            if (isJAN) {
                let Dec = 12
                return Dec
            } else {
                return today.getMonth()
            }
        },
        getProductionTab: function () {
            if (this.$store.state.dashboardPopularProductions !== {}) {
                let apiDatas = this.$store.state.dashboardPopularProductions

                // test-commit
                let apiKeys = Object.keys(apiDatas)
                let tabList = []
                let showTabAmount = 4
                for (let index = 0; index <= showTabAmount; index++) {
                    switch (apiKeys[index]) {
                        case 'month': {
                            break
                        }
                        case 'singlePaymentRank': {
                            tabList.push({
                                value: `singlePaymentRank`,
                                label: '躉繳'
                            })
                            break
                        }
                        case 'installmentPaymentRank': {
                            tabList.push({
                                value: `installmentPaymentRank`,
                                label: '期繳'
                            })
                            break
                        }
                        case 'oneYearPaymentRank': {
                            tabList.push({
                                value: `oneYearPaymentRank`,
                                label: 'ㄧ年期主約及附約'
                            })
                        }
                    }
                }
                return tabList
            } else {
                return {}
            }
        },
        isNotHasProductions: function () {
            let useApi = this.$store.state.dashboardPopularProductions
            if (useApi[this.currentMenuIndex]?.length === 0) {
                return true
            } else {
                return false
            }
        },
        isNotResponseAPIData: function () {
            if (
                Object.keys(this.$store.state.dashboardPopularProductions)
                    .length === 0
            ) {
                return true
            } else {
                return false
            }
        }
    },
    async created() {
        //為維持資料狀態為最新，設定每隔兩小時就要檢測API內容是否有更動
        let isNoAPIData =
            Object.keys(this.$store.state.dashboardPopularProductions)
                .length === 0

        if (
            !this.$store.state.dashboardPopularProductions ||
            (this.$store.state.dashboardPopularProductions && isNoAPIData) ||
            this.$shouldUpdateHomeApi()
        ) {
            await this.storeProductionsAPI()
        }
    }
}
</script>
<style lang="scss" scoped>
@mixin scrollBar() {
    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-thumb {
        background: $sum-row-grey;
        border-radius: 15px;
    }
    &::-webkit-scrollbar-track {
        background: rgba(255, 255, 255, 0.3);
        background: transparent;
    }
    //解決x,y軸交界處產生的異樣白色空區問題
    &::-webkit-scrollbar-corner {
        background: rgba(0, 0, 0, 0);
    }
}
.popular_productions-container {
    display: flex;
    flex-direction: column;

    width: 583px;
    height: 459px;

    box-sizing: border-box;
    padding: 10px 20px;

    @media screen and(max-width:1280px) {
        padding: 10px;
        width: auto;
    }
    @media screen and(max-width:576px) {
        // height: 488px;
        height: auto;
        &.unusual-height {
            height: 300px;
        }
    }

    :deep .dashboard {
        margin-bottom: 10px;
    }
    :deep .error-container {
        height: 100%;
    }
    .production-title {
        font-family: 'Noto Sans TC';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 26px;
        height: 30px;
    }
    .production-menu {
        display: flex;
        list-style: none;
        justify-content: center;
        gap: 45px;
    }
    .production-list-box {
        padding-bottom: 10px;
        border-bottom: 1px solid $sum-row-grey;

        @media screen and(max-width:576px) {
            padding: 0;
        }
        //TODO IOS14.5使用者比率上升後移除
        :deep .dashboard {
            gap: 0px;
            .menu-item {
                margin-right: 45px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        .column {
            display: grid;
            grid-template-columns: 40px 70px 223px 70px 40px;
            justify-content: space-between;
            justify-items: center;
            height: 40px;
            align-items: center;
            margin: 0;
            margin-bottom: 10px;

            list-style: none;
            padding: 0px 12px 0 0;

            li {
                font-family: 'Noto Sans TC';
                font-style: normal;
                font-weight: 400;
                font-size: 15px;
                line-height: 16px;

                color: $secondary-grey;
            }
            :nth-child(3) {
                text-align: center;
            }
            :nth-child(5) {
                text-align: end;
                justify-self: end;
            }
            @media screen and(max-width:576px) {
                display: none;
            }
        }
        .list {
            height: 224px;
            overflow-y: hidden;
            margin-right: -5px;

            @media screen and(max-width:576px) {
                height: 308px;
                margin-right: 0;
                overflow: hidden;
                &_show-all {
                    overflow: auto;
                    height: auto;
                }
            }
            .card {
                display: grid;
                grid-template-columns: 40px 70px 223px 70px 40px;
                justify-content: space-between;
                align-items: center;
                padding: 0 10px 0 0px;

                text-align: center;

                height: 40px;

                box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
                border-radius: 5px;

                margin-bottom: 8px;

                position: relative;

                :nth-child(5) {
                    justify-self: end;
                }
                @media screen and(max-width:576px) {
                    display: flex;
                    flex-direction: column;
                    align-items: space-between;
                    gap: 6px;

                    height: auto;
                    min-height: 140px;
                    width: auto;

                    padding: 10px;
                    box-sizing: border-box;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
                &-bg {
                    background: $primary-grey;
                }
                &_mobile {
                    display: none;
                    @media screen and(max-width:576px) {
                        display: block;
                    }
                }

                &-rank {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 20px;
                    color: $fourth-blue;
                    line-height: 16px;
                    text-align: center;

                    position: relative;
                    width: 40px;

                    &-icon {
                        margin: 0;
                        position: absolute;
                        top: -100%;
                        left: 34%;
                        img {
                            width: 13px;
                            height: 13px;
                        }
                    }
                    @media screen and(max-width:576px) {
                        display: none;
                    }
                }
                &-remark {
                    display: flex;
                    justify-content: center;
                    figure {
                        margin: 0;
                        padding: 10px;
                    }

                    img {
                        width: 20px;
                        height: 20px;
                    }
                    @media screen and(max-width:576px) {
                        display: flex;
                        justify-content: space-between;
                        width: 100%;
                        align-items: center;
                        order: 0;

                        .card-rank-icon {
                            position: relative;
                            padding: 0;
                            left: 0;
                        }
                        figcaption {
                            line-height: 10px;
                            font-family: 'Roboto';
                            font-style: normal;
                            font-weight: 700;
                            font-size: 20px;
                            line-height: 16px;
                            color: $fourth-blue;
                        }
                    }
                }
                &-company {
                    font-family: 'Noto Sans TC';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 17px;
                    color: $secondary-grey;
                    @media screen and(max-width:576px) {
                        display: flex;
                        justify-content: space-between;
                        width: 100%;
                        order: 1;
                    }
                }
                &-product {
                    font-family: 'Noto Sans TC';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 17px;
                    color: $secondary-grey;
                    @media screen and(max-width:576px) {
                        display: flex;
                        justify-content: space-between;
                        width: 100%;
                        order: 2;
                    }
                    &-name {
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        @media screen and(max-width:576px) {
                            max-width: 200px;
                            white-space: normal;
                            text-align: end;
                        }
                    }
                }
                &-price {
                    font-family: 'Noto Sans TC';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 17px;
                    color: $secondary-grey;
                    @media screen and(max-width:576px) {
                        display: flex;
                        justify-content: space-between;
                        width: 100%;
                        order: 3;
                    }
                }
            }
            &-showScroll {
                overflow-y: scroll;
                @include scrollBar();
                @media screen and(max-width:576px) {
                    overflow-y: hidden;
                }
            }
        }
        .arrow {
            display: none;

            @media screen and(max-width:576px) {
                padding: 5px 0;
                text-align: center;
                display: block;

                figure {
                    display: flex;
                    justify-content: center;
                    width: 17px;
                    height: 17px;
                    margin: 0 auto;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
                .rotate {
                    transform: rotateX(180deg);
                }
            }
        }
    }

    .production_diagram {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        padding-top: 10px;
        margin: 0 auto;

        @media screen and(max-width:576px) {
            display: grid;
            grid-template-columns: 1fr 1fr;
            justify-content: center;
            margin: 0;
            grid-gap: 10px;
            padding: 10px 0 0 0;

            .production_diagram_item {
                margin-bottom: 0;
            }
        }

        &_item {
            &:nth-child(1),
            &:nth-child(2) {
                margin-bottom: 15px;
                @media screen and(max-width:576px) {
                    margin-bottom: 0px;
                }
            }

            display: flex;
            flex-basis: 140px;

            flex-grow: 0;
            align-items: center;
            //TODO IOS14.5以下使用者比率上升後使用
            // gap: 10px;

            &:nth-child(odd) {
                margin-right: 80px;
                justify-content: center;
                @media screen and(max-width:1023px) {
                    justify-content: center;
                    margin-right: 0;
                }
            }
            @media screen and(max-width:1023px) {
                justify-content: center;
                flex-basis: 50%;
            }

            @media screen and(max-width:576px) {
                flex-basis: 140px;
            }

            figure {
                height: 20px;
                width: 20px;
                margin: 0;
                //TODO IOS14.5使用者比率上升後移除
                margin-right: 10px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
            figcaption {
                font-family: 'Noto Sans TC';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 17px;
                color: $secondary-grey;
                align-self: center;
                width: 108px;
                max-width: 108px;
            }
        }
    }
}
.autoheight {
    height: auto;
}

@media screen and (max-width: 576px) {
    .dashboard-title {
        margin-bottom: 0;
    }
    .production-list-box {
        padding: 0;
    }
}
</style>
